import React from 'react'

import Layout from '../components/Layout'
import Text from '../components/Text'

const NotFoundPage = () => (
  <Layout title="404 Page not found">
    <Text>This page could not be found.</Text>
  </Layout>
)

export default NotFoundPage
